<template>
  <div class="tutorial-screen-inner">
    <div class="tutorial-screen-left">
      <img src="/imgs/Menus-tutorial-1.png" alt="Menus Tutorial" />
    </div>
    <div class="tutorial-screen-right">
      <h3 class="screen-title">{{ info.title }}</h3>
      <div v-for="(item, index) in info.items" :key="index" class="item">
        <div>
          <h3 class="title">{{ item.title }}</h3>
          <p class="desc" v-for="(desc, idx) in item.desc" :key="idx">
            {{ desc }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        title: "MENÜ",
        items: [
          {
            title: "Neuer Import",
            desc: ["Hochladen CSV Datei, Zuordnung der Felder"]
          },
          {
            title: "IN BEARBEITUNG",
            desc: [
              "Anzeige des noch nicht fertig gestellten Auftrages",
              "Export der Artikel mit eindeutigem EAN/VAN Mapping"
            ]
          },
          {
            title: "ERGEBNISSE",
            desc: ["Anzeige, Bearbeitung und Export der Ergebnisse"]
          },
          {
            title: "EXPORT",
            desc: [
              "Export der Artikel, bei denen EAN/VAN eindeutig erkannt wurde"
            ]
          },
          {
            title: "AUTO-MAPPING",
            desc: [
              "Anzeige, Bearbeitung und Export der Ergebnisse ab 75% Wahrscheinlichkeit"
            ]
          },
          {
            title: "MANUELLES MAPPING",
            desc: [
              "Bearbeitung und Export aller Ergebnisse mit nicht eindeutigem EAN/VAN Mapping"
            ]
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.tutorial-screen {
  &-inner {
    padding-right: 40px;
    padding-left: 65px;
    display: flex;
    align-items: center;
  }
  &-left {
    img {
      width: 100%;
    }
  }
  &-right {
    margin-left: 50px;
    .screen-title {
      font-size: 30px;
      line-height: 36px;
      text-transform: uppercase;
      margin-bottom: 32px;
      color: #555555;
    }
    .item {
      margin-bottom: 30px;
      color: #555;
      .title {
        font-size: 16px;
      }
      .desc {
        font-size: 14px;
        margin-top: 7px;
      }
    }
  }
}
</style>